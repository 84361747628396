<template>
  <b-container
    v-bind:class="{ 'p-0': isIFrame }"
    :fluid="isIFrame"
    v-if="event"
    class="event"
  >
    <b-row v-if="!isIFrame" class="my-2">
      <b-col>
        <h2 class="text-center" v-if="event.title ? t(event.title) : false">
          {{ event ? t(event.title) : "" }}
        </h2>
      </b-col>
    </b-row>

    <b-row v-if="!isIFrame">
      <b-col class="my-4">
        <div
          v-if="
            event.questions &&
            ((event.requirePII && user.PII) || !event.requirePII)
          "
          class="mt-4 questions"
        >
          <h5 class="text-center">
            All questions
          </h5>
          <b-row>
            <b-col cols="10">
              <b-form-input
                id="input-qpwd"
                v-model="questionpassword"
                placeholder="Question password"
              ></b-form-input
            ></b-col>
            <b-col>
              <b-button @click="updateUsersQuestionPassword(questionpassword)"
                >Change password</b-button
              ></b-col
            >
          </b-row>
          <b-list-group class="my-4">
            <b-list-group-item
              class="d-flex align-items-left"
              v-for="question in questions"
              :key="question.id"
              style="color: #000000"
            >
              <b>{{ question.question.name }}
                {{ question.question.extrafield1 ? " - "+question.question.extrafield1 : null }}
                {{ question.question.extrafield2 ? " - "+question.question.extrafield2 : null }}
                {{ question.question.extrafield3 ? " - "+question.question.extrafield3 : null }}
                {{ question.question.extrafield4 ? " - "+question.question.extrafield4 : null }}
                {{ question.question.extrafield5 ? " - "+question.question.extrafield5 : null }}</b
              ><span class="mx-1"> - {{ question.question.text }}</span>
              <!--<b-icon
                icon="x-circle"
                class="rounded-circle bg-danger"
                variant="light"
                v-on:click="deleteQuestion(question.id)"
              ></b-icon>-->
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="PII" v-if="user.name">
          {{ t(event.PIIprivacy.loggedintext) || "Stai visualizzando come: "
          }}<b>{{ user.name }} {{ user.surname }}</b> (<a
            href="#"
            @click.prevent="logOut()"
            >{{ t(event.PIIprivacy.logoutbutton) || "ESCI" }}</a
          >)
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<style>
@import "https://cdn.myth.theoplayer.com/f7d7a378-012f-4a04-a037-4af7c65bd03d/ui.css";

/* Customization css style */
.theo-primary-color,
.vjs-selected {
  color: #ace1e2 !important;
}

.theo-primary-background {
  color: #000000 !important;
  background-color: #ace1e2 !important;
}

.theo-secondary-color {
  color: #ffffff !important;
}

.theo-secondary-background {
  color: #000000 !important;
  background-color: #ffffff !important;
}

.theo-tertiary-color {
  color: #000000 !important;
}

.theo-tertiary-background {
  color: #ffffff !important;
  background-color: #000000 !important;
}

.jw-icon.jw-icon-tooltip.jw-icon-volume.jw-button-color.jw-reset.jw-off {
  color: red;
}

.jw-icon.jw-icon-tooltip.jw-icon-volume.jw-button-color.jw-reset.jw-off.jw-open {
  color: red;
}

/*.player,
.coming,
.description,
.PII
.questions {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5em;
}*/

.alert {
  max-width: 800px;
  margin: 0 auto;
  padding: 1.5em;
}

.btn.active {
  background-color: #ace1e2 !important;
  color: black !important;
}
</style>

<script>
import * as fb from "./../firebase";
import getBrowserLocale from "@/util/get-browser-locale";

export default {
  data() {
    return {
      isIFrame: false,
      locale: "",
      authenticated: false,
      uid: "",
      user: {},
      formPII: {},
      eventname: null,
      event: null,
      questions: [],
      state: false,
      player: null,
      currentSrc: null,
      currentSrcLang: null,
      currentAudioTrackLanguageCode: null,
      audioTracks: null,
      questionform: {
        email: "",
        name: "",
        question: "",
      },
      questionpassword: "",
      watchingLogInterval: null,
    };
  },
  mounted() {
    this.locale = this.$route.query.lc || getBrowserLocale() || "en";
    this.isIFrame = window.location !== window.parent.location;

    //LOAD PLAYER SCRIPTS
    /*let castFwScript = document.createElement("script");
    castFwScript.setAttribute(
      "src",
      "//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"
    );
    document.head.appendChild(castFwScript);*/

    /*let theoScript = document.createElement("script");
    theoScript.setAttribute(
      "src",
      "https://cdn.myth.theoplayer.com/f7d7a378-012f-4a04-a037-4af7c65bd03d/THEOplayer.js"
    );
    document.head.appendChild(theoScript);*/

    let jwScript = document.createElement("script");
    jwScript.setAttribute(
      "src",
      "https://cdn.jwplayer.com/libraries/Ac9ECv51.js"
    );
    document.head.appendChild(jwScript);

    //fb.auth.setPersistence(fb.authPersistence.SESSION)

    if (!fb.auth.currentUser) {
      fb.auth
        .signInAnonymously()
        .then(() => {
          this.authenticated = true;
          this.uid = fb.auth.currentUser.uid;
          this.getEventByUrl();
        })
        .catch((error) => {
          error.code;
          error.message;
          this.authenticated = false;
        });
    } else {
      this.authenticated = true;
      this.uid = fb.auth.currentUser.uid;
      this.getEventByUrl();
    }
  },
  beforeDestroy() {
    this.watchingLogInterval ? clearInterval(this.watchingLogInterval) : "";
  },
  methods: {
    //method to get translation from the object
    t(p) {
      if (typeof p === "object" && p !== null) {
        if (p[this.locale] !== undefined && p[this.locale] !== "") {
          return p[this.locale];
        } else if (p['en'] !== undefined && p['en'] !== "") {
          return p['en'];
        } else {
          return p[Object.keys(p)[0]];
        }
      } else {
        return p;
      }
    },
    logOut() {
      fb.auth
        .signOut()
        .then(() => {
          window.location.reload();
        })
        .catch((error) => {
          console.log("error during logout: ", error);
        });
    },
    updateUsersData(PII = false) {
      var docRef = fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .doc(fb.auth.currentUser.uid);

      docRef
        .get()
        .then((doc) => {
          if (
            doc.exists &&
            doc.data().last_tenant != this.$route.params.tenantname
          ) {
            this.logOut();
          } else {
            let usersData = {};
            if (PII) {
              usersData = {
                name: this.formPII.name,
                surname: this.formPII.surname,
                email: this.formPII.email,
                last_update: fb.serverTs,
                last_tenant: this.$route.params.tenantname,
                extrafield1: this.formPII.extrafield1 || null,
                extrafield2: this.formPII.extrafield2 || null,
                extrafield3: this.formPII.extrafield3 || null,
                extrafield4: this.formPII.extrafield4 || null,
                extrafield5: this.formPII.extrafield5 || null,

                PII: true,
              };
            } else {
              usersData = {
                last_update: fb.serverTs,
                last_tenant: this.$route.params.tenantname,
              };
            }
            docRef
              .set(usersData, { merge: true })
              .then(function () {
                //
              })
              .catch(function (error) {
                console.log("Error updating document: ", error);
              });
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },
    updateUsersQuestionPassword(questionpassword) {
      var docRef = fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .doc(fb.auth.currentUser.uid);

      docRef
        .get()
        .then(() => {
          let usersData = {
            pwddomande: questionpassword,
          };

          docRef
            .set(usersData, { merge: true })
            .then(function () {
              window.location.reload();
            })
            .catch(function (error) {
              console.log("Error updating document: ", error);
            });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    },

    getEventByUrl() {
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("event_links")
        .where("url_name", "==", this.$route.params.eventname)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.eventname = doc.data().event_ref;
          });
          this.startFb();
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          this.eventname = this.$route.params.eventname;
        });
    },

    startFb() {
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .doc(fb.auth.currentUser.uid)
        .onSnapshot((doc) => {
          if (doc.data()) {
            this.user = doc.data();
          }
        });
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("config")
        .doc("0")
        .onSnapshot(
          {
            // Listen for document metadata changes
            includeMetadataChanges: true,
          },
          (doc) => {
            this.event = doc.data();
            //this.switchEvent(this.event.state);
          }
        );
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("questions")
        .orderBy("server_ts", "asc")
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              let question = { id: change.doc.id, question: change.doc.data() };
              this.questions.push(question);
            }
            if (change.type === "modified") {
              console.log("Modified question: ", change.doc.data());
            }
            if (change.type === "removed") {
              let question = { id: change.doc.id, question: change.doc.data() };
              this.questions.splice(this.questions.indexOf(question), 1);
              console.log("Removed question: ", change.doc.data());
            }
          });
        });
    },
    switchEvent(state) {
      switch (state) {
        case "coming":
          if (this.state != "coming") {
            this.state = "coming";
            setTimeout(() => {
              this.destroyPlayerJW();
            }, 250);
          }
          break;
        case "live":
          if (this.state != "live") {
            this.state = "live";
            this.checkRequirePII();
          }
          break;
        default:
          this.state = null;
          this.player = null;
          break;
      }
    },
    checkRequirePII() {
      if (this.event.requirePII) {
        if (this.user.PII) {
          this.state = "live";
          setTimeout(() => {
            this.loadPlayerJW();
          }, 250);
        }
      } else {
        this.state = "live";

        setTimeout(() => {
          this.loadPlayerJW();
        }, 250);
      }
    },
    loadPlayer() {
      if (this.player == null) {
        this.player = new window.THEOplayer.Player(
          document.getElementById("playerdiv"),
          {
            libraryLocation:
              "https://cdn.myth.theoplayer.com/f7d7a378-012f-4a04-a037-4af7c65bd03d",
            //mutedAutoplay: "all",
            license:
              "sZP7IYe6T6P60lXK3uPg3Zzc3S0LFSakCLR-CKBiIOzz3SezIu1lClC_0Qf6FOPlUY3zWokgbgjNIOf9fKBk3l0LIDaoFDaz3La-3Qak3Ok13DhZFDBZ3l0L3K0k0KBz0ZfVfK4_bQgZCYxNWoryIQXzImf90SCZTu5t0Sfi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3l5rTuhL3SBL3S5ZFOPeWok1dDrLYtA1Ioh6TgV6UQ1gWtAVCYggb6rlWoz6FOPVWo31WQ1qbta6FOPqUorlCY3zFD41btepUo46FKXibOfVfKcNUQhpWtUpCoXLUOrVdYIgfgzVfKxqWDXNWG3ybojkbK3gflNWfGxEIDjiWQXrIYfpCoj-f6i6WQjlCDcEWt3zf6i6v6PUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz",
          }
        );
      }
      // OPTIONAL CONFIGURATION

      // Customized video player parameters
      this.player.source = {
        sources: [
          {
            src: this.event.sources[0].url,
            type: "application/x-mpegurl",
            lowLatency: false,
          },
        ],
        // Poster image
        poster: this.event.player_poster_url,
      };
      this.player.autoplay = true;
      this.player.preload = "auto";

      this.player.addEventListener("destroy", () => {
        this.player = null;
      });

      this.player.addEventListener("sourcechange", () => {
        this.currentSrc = this.player.src;
        this.currentSrcLang = this.event.sources.find(
          (v) => v.url === this.player.src
        ).language_code;
      });

      this.player.addEventListener(
        [
          "durationchange",
          "stalled",
          "ended",
          "seeking",
          "seeked",
          "waiting",
          "playing",
          "pause",
          "volumechange",
        ],
        (event) => {
          fb.analytics.logEvent("video_event", {
            eventAction: event.type,
            videoSrc: this.player.src,
            eventValue: Math.floor(
              this.player.currentProgramDateTime || this.player.currentTime
            ),
          });
        }
      );

      this.player.addEventListener(["playing"], () => {
        this.watchingLogInterval = setInterval(() => {
          this.logWatching();
        }, 10000);
      });
      this.player.addEventListener(["pause", "waiting", "ended"], () => {
        this.watchingLogInterval ? clearInterval(this.watchingLogInterval) : "";
      });
    },
    destroyPlayer() {
      if (this.player) {
        this.player.destroy();
        this.watchingLogInterval ? clearInterval(this.watchingLogInterval) : "";
      }
    },
    loadPlayerJW() {
      if (this.player == null) {
        // eslint-disable-next-line
        this.player = jwplayer("playerdiv").setup({
          file: this.event.sources[0].url,
          autostart: "viewable",
          cast: {},
        });
        this.currentSrc = this.event.sources[0].url;

        /*this.player.on("remove", () => {
          this.player = null;
        });*/
        this.player.on("play", () => {
          if (!this.watchingLogInterval) {
            this.watchingLogInterval = setInterval(() => {
              this.logWatching();
            }, 10000);
            this.currentAudioTrackLanguageCode =
              this.player.getAudioTracks()[
                this.player.getCurrentAudioTrack()
              ].language;
          }
        });
        this.player.on("pause", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("idle", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("error", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("complete", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("audioTracks", (levels) => {
          this.audioTracks = levels;
        });
        this.player.on("audioTrackChanged", (track) => {
          this.currentAudioTrackLanguageCode =
            this.player.getAudioTracks()[track.currentTrack].language;
        });

        const buttonId = "change-audio-language-button";
        const iconPath = "/assets/audio-language-icon-4.png";

        // Call the player's `addButton` API method to add the custom button
        this.player.addButton(
          iconPath,
          null,
          this.toggleAudioTracksMenu,
          buttonId
        );
      }
    },
    toggleAudioTracksMenu() {
      setTimeout(function () {
        document
          .getElementsByClassName("jw-settings-audioTracks")[0]
          .dispatchEvent(new Event("click"));
      }, 10);
    },
    destroyPlayerJW() {
      if (this.player) {
        this.player.remove();
        this.watchingLogInterval ? clearInterval(this.watchingLogInterval) : "";
        this.player = null;
      }
    },
    logWatching() {
      console.log("log watching");
      let last_heartbeat_ts = Math.floor(Date.now() / 1000);

      //last log
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .doc(this.uid)
        .set(
          {
            last_heartbeat_ts: last_heartbeat_ts,
            last_src_lang: this.currentSrcLang,
            last_audio_track_language_code: this.currentAudioTrackLanguageCode,
            updated_at: fb.serverTs,
          },
          { merge: true }
        )
        .then(function () {
          //console.log(Math.floor(Date.now() / 1000));
        })
        .catch(function (error) {
          console.log("Error updating document: ", error);
        });

      //history log
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .doc(this.uid)
        .collection("history")
        .doc(last_heartbeat_ts.toString())
        .set(
          {
            last_heartbeat_ts: last_heartbeat_ts,
            last_src_lang: this.currentSrcLang,
            last_audio_track_language_code: this.currentAudioTrackLanguageCode,
            updated_at: fb.serverTs,
          },
          { merge: true }
        )
        .then(function () {
          //
        })
        .catch(function (error) {
          console.log("Error updating document: ", error);
        });

      //google analytics log
      fb.analytics.logEvent("video_event", {
        eventAction: "watching",
        videoSrc: this.player.src,
        videoTime: Math.floor(
          this.player.currentProgramDateTime || this.player.currentTime
        ),
      });
    },
    queryViewers() {
      let date = new Date();
      date.setSeconds(date.getSeconds() - 90);
      let usercount = 9999;
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("viewers")
        .where("updated_at", ">=", date)
        .get()
        .then((querySnapshot) => {
          console.log("query size: " + querySnapshot.size);
          usercount = querySnapshot.size;
          console.log("user count: " + usercount);
        });
    },
    setSource(url) {
      this.player.src = url;
      this.player.play();
    },
    setSourceJW(url) {
      this.destroyPlayerJW();
      setTimeout(() => {
        // eslint-disable-next-line
        this.player = jwplayer("playerdiv").setup({
          file: url,
          autostart: "viewable",
          cast: {},
        });
        //this.player.load();
        this.currentSrc = url;
        this.currentSrcLang = this.event.sources.find(
          (v) => v.url === url
        ).language_code;

        /*this.player.on("remove", () => {
            this.player = null;
          });*/
        this.player.on("play", () => {
          console.log("play event");
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
          if (!this.watchingLogInterval) {
            this.watchingLogInterval = setInterval(() => {
              this.logWatching();
            }, 10000);
            this.currentAudioTrackLanguageCode =
              this.player.getAudioTracks()[
                this.player.getCurrentAudioTrack()
              ].language;
          }
        });
        this.player.on("pause", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("idle", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("error", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("complete", () => {
          this.watchingLogInterval
            ? clearInterval(this.watchingLogInterval)
            : "";
          this.watchingLogInterval = null;
        });
        this.player.on("audioTracks", (levels) => {
          this.audioTracks = levels;
          if (levels.tracks.length > 1) {
            const buttonId = "change-audio-language-button";
            const iconPath = "/assets/audio-language-icon-4.png";

            // Call the player's `addButton` API method to add the custom button
            this.player.addButton(
              iconPath,
              null,
              this.toggleAudioTracksMenu,
              buttonId
            );
          }
        });
        this.player.on("audioTrackChanged", (track) => {
          this.currentAudioTrackLanguageCode =
            this.player.getAudioTracks()[track.currentTrack].language;
        });
      }, 250);
    },
    sendQuestion() {
      event.preventDefault();

      var questionData = {
        uid: this.uid,
        name:
          this.user.name + " " + this.user.surname || this.questionform.name,
        email: this.user.email || this.questionform.email,
        text: this.questionform.question,
        timestamp: Date.now(),
        server_ts: fb.serverTs,
      };

      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("questions")
        .add(questionData)
        .then((docRef) => {
          let question = { id: docRef.id, question: questionData };
          this.questions.push(question);
          this.questionform.question = "";
        })
        .catch((error) => {
          console.error("Error adding new question:: ", error);
        });
    },
    deleteQuestion(questionid) {
      fb.db
        .collection("tenants")
        .doc(this.$route.params.tenantname)
        .collection("events")
        .doc(this.eventname)
        .collection("questions")
        .doc(questionid)
        .delete()
        .then(() => {
          //console.log("Question successfully deleted!");
          const indx = this.questions.findIndex((v) => v.id === questionid);
          this.questions.splice(indx, indx >= 0 ? 1 : 0);
        })
        .catch(function (error) {
          console.error("Error removing question: ", error);
        });
    },
    orderSources(a, b) {
      if (a.language_code < b.language_code) {
        return -1;
      }
      if (a.language_code < b.language_code) {
        return 1;
      }
      return 0;
    },
  },
  watch: {
    event: function () {
      this.event.title
        ? (document.title = "Questions: " + this.t(this.event.title))
        : (document.title = "Questions: Owncast");
      this.event.sources.sort(this.orderSources);
    },
    user: function (nv, ov) {
      if (ov.PII != nv.PII) {
        this.event && this.event.requirePII
          ? this.state == "live"
            ? this.checkRequirePII()
            : null
          : null;
      }
      if (nv.last_tenant != this.$route.params.tenantname) {
        this.logOut();
      }
    },
  },
};
</script>

